body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: whitesmoke; */
}
input {
  -webkit-user-select: text;
}
textarea {
  -webkit-user-select: text;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
img {
  pointer-events: none;
}

.flex-box input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* scroll bar  */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
html {
  scroll-behavior: smooth;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #816333;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #81633399;
}

/* image */
.image-div-style {
  display: flex;
  justify-content: center;
}
* {
  user-select: none;
}
.image-pos-style {
  height: 0pt;
  display: none;
  position: absolute;
  z-index: -65537;
}

.image-look-style {
  display: none;
  margin: 0 auto;
  opacity: 0.1;
}

/* Text */
.text-center-align {
  text-align: center;
  margin-top: 10px;
}

.text-td-head {
  border-bottom-style: solid;
  border-bottom-width: 0.75pt;
  vertical-align: top;
}

.text-td {
  vertical-align: top;
  padding-right: 5px;
}

.text-td-ar {
  vertical-align: top;
  text-align: right;
  padding-right: 5px;
}

.text-td-en {
  vertical-align: top;
  text-align: left !important;
  width: 50%;
  max-width: 50%;
  padding-left: 5px;
}

.text-td-head-ar {
  border-bottom-style: solid;
  border-bottom-width: 0.75pt;
  vertical-align: top;
  padding-right: 5px;
  width: 33%;
}

.text-td-head-en {
  border-bottom-style: solid;
  border-bottom-width: 0.75pt;
  vertical-align: top;
}

.text-p-head {
  /* margin-top: 6pt;
    margin-bottom: 5pt; */
  margin: 5pt 5pt 5pt 0;
  text-align: center;
  line-height: 120%;
  font-size: 14pt;
}

.text-p-small-head {
  margin-top: 6pt;
  margin-bottom: 0pt;
  line-height: 120%;
  font-size: 14pt;
}
b {
  font-weight: 600;
  margin: 0 10px;
  font-size: 14px;
}
.text-td .text-p {
  text-align: justify;
}

.text-p {
  margin-top: 6pt;
  margin-bottom: 5pt;
  line-height: 150%;
  font-size: 10.88pt;
}

.heading-style-ar {
  font-family: "PT Bold Heading";
  font-size: 15pt;
  color: #ff0000;
}

.heading-style-en {
  font-weight: bold;
  color: #ff0000;
}

.text-style-ar {
  font-size: 10.88pt;
}
.mx {
  margin: 0 10px;
}

.text-style-ar-head {
  font-size: 15pt;
}

.text-style-ar-head-ul {
  font-size: 15pt;
  text-decoration: underline;
}

.text-style-en-head-ul {
  font-size: 14pt;
  font-weight: bold;
  text-decoration: underline;
}

.text-li-en {
  margin-left: 10pt;
  text-align: justify;
  line-height: 150%;
  /* font-size: 14pt; */
}

.text-li-ar {
  margin-right: 33.83pt;
  text-align: right;
  line-height: 150%;
  padding-right: 2.17pt;
  font-size: 14pt;
}

/* table */
@media print {
  table {
    page-break-inside: auto;
  }

  .un-print {
    display: none;
  }
  .image-pos-style,
  .image-look-style {
    display: block;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  ol {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }
  @page footer {
    display: none;
  }
  @page header {
    display: none;
  }
}
@media print {
  .flex-box-between {
    position: absolute;
    width: 100%;
    bottom: 0;
    color: gray;
    padding: 10px 30px 0 30px;
    justify-content: space-between;
    align-items: center;
    display: flex !important;
  }
  .page {
    size: a4;
    padding-top: 20px;
    position: relative;

    margin: auto;
    width: 210mm;
    height: 290mm;
    page-break-inside: avoid;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .page-break {
    page-break-after: always !important;
  }
  .break {
    page-break-after: always;
  }
}
.break {
  margin-top: 15px;
  font-size: 12px;
}
.no-break {
  margin: 15px;
  font-size: 12px;
}

@media only screen and (max-width: 320px) {
  .table-style {
    width: 210px !important;
  }
}
@media only screen and (max-width: 375px) {
  .table-style {
    width: 210px !important;
  }
}
@media only screen and (max-width: 600px) {
  #padding-li {
    padding-left: 5px;
  }
  .text-li-en {
    line-height: 70%;
    padding-left: 5px;
  }
  #li {
    line-height: 100%;
  }
  #card {
    width: 355px;
  }
  .table-style span,
  .table-style p,
  .table-style b,
  .text-style-ar {
    font-size: 8px !important;
  }
}
.table-style {
  max-width: 550.25pt;
  margin-right: auto;
  margin-left: auto;
  border: 0.75pt solid #000000;
  border-collapse: collapse;
  width: 100%;
}

.top-border {
  border-top-style: solid;
  border-top-width: 0.75pt;
  vertical-align: top;
}

.arg-title {
  font-size: x-large;
  color: red;
  font-weight: 600;
  text-align: center;
}
.arg-subtitle2 {
  color: red;
}
.flex-box {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.flex-box-between {
  display: none;
}
.text-center {
  text-align: center;
}
.arg-subtitle {
  font-size: large;
  font-weight: 600;
  padding: 2px;
  /* margin-top: 10px; */
}
.arg-table {
  width: 100%;
  max-width: 790px;
  max-width: 550.25pt;
  margin-right: auto;
  margin-left: auto;
  line-height: 150%;
  border: 0.75pt solid #000000;
  border-collapse: collapse;
}
.arg-table td {
  width: 50%;
  max-width: 50%;
  padding: 7px;
  /* font-weight: 500; */
  font-size: medium;
}
/* .arg-table td {
  border-bottom: 1px solid #333;
} */
.arg-table {
  border: 1px solid #333;
  border-collapse: collapse;
}
.table-borders {
  border: 1px solid #333;
}
.border-bottom {
  border-bottom: 1px solid #333;
  border-top: 1px solid #333;
  margin-top: 10px;
}

/* new styles */
